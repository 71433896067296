export default function Checkbox({ name, value, handleChange, className, isChecked, isDisabled = false }) {
    return (
        <input
            type="checkbox"
            name={name}
            value={value}
            className={`checkbox${className ? ` ${className}` : ''}`}
            onChange={handleChange}
            checked={isChecked}
            disabled={isDisabled}
        />
    );
}
